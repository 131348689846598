import { admin2 } from '@/api/instance'

export const GetImportSchema = async ({
  shopId,
  type,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopBatchImportTask/findSchema`,
    params: {
      type,
    },
  })
}

export const CreateImportTask = async ({
  shopId,
  type,
  fileId,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/shopBatchImportTask`,
    data: {
      type,
      fileId,
    },
  })
}

export const FindImport = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopBatchImportTask/${id}`,
  })
}

export const ImportFile = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/shopBatchImportTask/${id}/import`,
    data: {},
  })
}

export const FindImportContentError = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopBatchImportTask/${id}/contentErrors`,
  })
}

export const ConfirmImportContentError = async ({
  shopId,
  id,
  allProcessingType,
  processings,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/shopBatchImportTask/${id}/confirmContentErrors`,
    data: {
      allProcessingType,
      processings,
    },
  })
}

export const FindImportContentDuplicated = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopBatchImportTask/${id}/duplicateErrors`,
  })
}

export const ConfirmImportContentDuplicated = async ({
  shopId,
  id,
  allProcessingType,
  processings,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/shopBatchImportTask/${id}/confirmDuplicateErrors`,
    data: {
      allProcessingType,
      processings,
    },
  })
}
