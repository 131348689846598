<template>
  <div class="import-result">
    <div v-if="!confirmImport">
      <div v-if="curTab === 'error'" class="tabTitle">
        <p class="font-medium" style="font-size: 20px; margin-right: 12px">匯入處理結果</p>
        <p class="font-medium text-danger">*請確實處理完所有錯誤才能進行下一步</p>
      </div>
      <div v-if="curTab === 'duplicated'" class="tabTitle">
        <p class="font-medium"  style="font-size: 20px; margin-right: 12px">匯入處理結果</p>
        <p class="font-medium text-danger">*請確實處理完所有統一編號重複的名單才能完成匯入</p>
      </div>
      <el-tabs v-if="!loading" v-model="curTab">
        <el-tab-pane v-if="curTab === 'error'" :label="`錯誤資訊處理（${counting.errors}）`" name="error">
          <ImportErrorTab v-if="result.errors" :importError="result.errors" :schema="schema" @done="onDone('error')" />
        </el-tab-pane>
        <el-tab-pane v-if="curTab === 'duplicated'" :label="`重複名單處理（${counting.duplicated}）`" name="duplicated">
          <ImportDuplicatedTab v-if="result.duplicated" :importError="result.duplicated" :schema="schema" @done="onDone('duplicated')" />
        </el-tab-pane>
      </el-tabs>
    </div>

    <div v-if="confirmImport">
      <PageTitle title="匯入執行確認" hideBtn />
      <div v-if="counting.errors <= 100 && counting.duplicated <= 100">
        <p>本次匯入：預計執行匯入筆數 {{ get(importDetail, 'rowPreparedImportCount', 0) }} 筆，未執行匯入筆數 {{ get(importDetail, 'rowPreparedMissingCount', 0) }} 筆。</p>
        <p>
          <span>請確認是否執行匯入？</span>
          <span class="text-danger">（若取消匯入，此次調整內容將不會生效）</span>
        </p>
      </div>

      <div v-if="counting.errors > 100 && !counting.duplicated">
        <div>
          <p>錯誤資訊筆數超過 100 筆，系統將自動移除所有錯誤名單後上傳其餘正確名單 </p>
          <p>請確認是否執行匯入？</p>
        </div>
      </div>

      <div v-if="counting.errors > 100 && counting.duplicated">
        <div>
          <p>錯誤或重複資訊筆數超過 100 筆，系統將自動移除所有錯誤名單後上傳其餘正確名單</p>
          <p>請選擇是否針對重複名單進行全部取代（以最新資料取代既有重複名單） 或是取消此次匯入？</p>
        </div>
      </div>

      <div v-if="!counting.errors && counting.duplicated > 100">
        <div>
          <p>重複資訊筆數超過 100 筆。</p>
          <p>請選擇是否針對重複名單進行全部取代（以最新資料取代既有重複名單） 或是取消此次匯入？</p>
        </div>
      </div>
      <PageFixedFooter
        :confirmLoading="loading"
        @cancel="$router.go(-1)"
        @confirm="onConfirm"
      />
    </div>

    <WarningDialog
      v-if="dialog.warning"
      @confirm="onWarningConfirm"
      @cancel="onWarningCancel"
      @close="dialog.warning = false"
    >
      cascs
    </WarningDialog>
  </div>
</template>

<script>
import ImportErrorTab from './ImportErrorTab.vue'
import ImportDuplicatedTab from './ImportDuplicatedTab.vue'
import {
  GetImportSchema,
  FindImport,
  FindImportContentError,
  FindImportContentDuplicated,
  ImportFile,
} from '@/api/import'
import { mapGetters } from 'vuex'
import { showErrorMsg } from '@/utils/helper'
import { get } from 'lodash'

export default {
  name: 'ImportResult',
  components: { ImportErrorTab, ImportDuplicatedTab },
  data: () => ({
    curTab: 'error',
    loading: false,
    schema: {},
    importDetail: null,
    confirmImport: false,
    totalErrorCount: 0,
    totalDuplicatedCount: 0,

    dialog: {
      warning: false,
    },

    counting: {
      errors: 0,
      duplicated: 0,
    },

    result: {
      errors: null,
      duplicated: null,
    },
  }),
  computed: {
    ...mapGetters(['shop']),
    taskId () {
      return this.$route.params.taskId
    },
    pageType () {
      return this.$route.query.pageType
    },
    sortSchema () {
      const list = []
      for (const key in this.schema) {
        list.push({
          key,
          ...this.schema[key],
        })
      }
      list.sort((a, b) => a.order - b.order)
      return list
    },
  },
  async mounted () {
    this.$store.commit('app/SET_SIDEBAR', false)
    this.findImportDetail()
    await this.getSchema()
    if (!await this.findImportContentError()) return
    await this.findImportContentDuplicated()
  },
  methods: {
    get,
    async onConfirm () {
      this.loading = true
      const [res, err] = await ImportFile({
        shopId: this.shop,
        id: this.taskId,
      })
      if (showErrorMsg(this, err)) {
        this.loading = false
        return
      }
      await this.checkImporting()
    },
    // 檢查匯入狀態
    async checkImporting () {
      const [taskDetail, findErr] = await FindImport({
        shopId: this.shop,
        id: this.taskId,
      })
      if (showErrorMsg(this, findErr)) {
        this.loading = false
        return
      }

      const status = taskDetail.state

      if (status === 'IMPORTING') {
        setTimeout(async () => {
          await this.checkImporting()
        }, 2000)
      }
      if (status === 'FAIL') {
        this.$message.error('匯入時發生錯誤！')
        this.$router.push({
          name: 'CompanyContactMenu',
          query: {
            taskId: this.taskId,
          },
        })
        return
      }
      if (status === 'DONE' || status === 'CANCEL') {
        this.$router.push({
          name: 'CompanyContactMenu',
          query: {
            taskId: this.taskId,
          },
        })
      }
    },
    async getSchema () {
      const [res, err] = await GetImportSchema({
        shopId: this.shop,
        type: 'CompanyDirectoryFirm',
      })
      if (showErrorMsg(this, err)) return
      this.schema = res.schema
    },
    // -- error --
    async findImportDetail () {
      const [res, err] = await FindImport({
        shopId: this.shop,
        id: this.taskId,
      })
      if (showErrorMsg(this, err)) return
      this.importDetail = res
    },
    async findImportContentError () {
      const [res, err] = await FindImportContentError({
        shopId: this.shop,
        id: this.taskId,
      })
      if (showErrorMsg(this, err)) return
      this.result.errors = res
      if (res.rowContentErrorCount) {
        this.counting.errors = res.rowContentErrorCount
        this.curTab = 'error'
        return false
      }
      if (!res.rowContentErrorCount && res.state === 'CHECKED') {
        await this.findImportDetail()
        this.confirmImport = true
        return false
      }
      return true
    },
    // -- duplicated --
    async findImportContentDuplicated () {
      const [res, err] = await FindImportContentDuplicated({
        shopId: this.shop,
        id: this.taskId,
      })
      if (showErrorMsg(this, err)) return
      this.result.duplicated = res
      if (res.rowDuplicateErrorCount) {
        this.counting.duplicated = res.rowDuplicateErrorCount
        this.curTab = 'duplicated'
      }
      if (!res.rowDuplicateErrorCount && res.state === 'CHECKED') {
        await this.findImportDetail()
        this.confirmImport = true
      }
    },

    async onDone (type) {
      this.loading = true
      if (!await this.findImportContentError()) {
        this.loading = false
        return
      }
      await this.findImportContentDuplicated()
      this.loading = false
    },
  },
}
</script>

<style scoped lang="postcss">
.tabTitle {
  @apply flex items-center mb-[20px];
}
::v-deep .el-tabs__item.is-active {
  @apply text-primary-100;
}

::v-deep .el-tabs__active-bar {
  @apply bg-primary-100;
}

::v-deep .el-tabs__nav-wrap::after {
  @apply hidden;
}
</style>
