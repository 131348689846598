<template>
  <div class="import-error-tab">
    <div class="flex items-center" style="gap: 4px; margin-bottom: 20px">
      <el-checkbox v-model="skipAll" label="1">忽略全部</el-checkbox>
      <el-tooltip placement="right">
        <div slot="content">
          說明：忽略全部錯誤名單不匯入，僅<br>匯入其餘正確名單
        </div>
        <span class="material-icons" style="font-size: 18px">
          help_outline
        </span>
      </el-tooltip>
    </div>
    <el-table :data="compactRawData[tableOptions.page - 1]">
      <EmptyBlock slot="empty" />
      <el-table-column
        v-for="(row) in sortSchema"
        :key="row.key"
        :label="row.text"
        align="center"
        :fixed="['taxId', 'name', 'serialNumber'].includes(row.key) ? 'left' : false"
      >
        <template slot-scope="scope">
          <div>
            {{ scope.row[row.key] || '-' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="錯誤資訊" width="550" fixed="right" align="center">
        <template slot-scope="scope">
          <div class="text-danger">
            {{ showErrorInfo(scope.row.errors) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        fixed="right"
        width="110"
        align="center"
      >
        <template slot-scope="scope">
          <TableEditBtnGroup
            deleteBtn="忽略"
            :editDisabled="isDisabled(scope.row)"
            :deleteDisabled="isDisabled(scope.row)"
            @edit="onRowEdit(scope.row)"
            @delete="onRowSkip(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableOptions.dataCount"
      @pageChange="changePage"
    />
    <PageFixedFooter
      :confirmLoading="loading"
      :disabledConfirm="disabledConfirm"
      @cancel="$router.go(-1)"
      @confirm="onConfirm"
    />
    <EditErrorDialog
      v-if="dialog.edit"
      :schema="schema"
      :rowData="selectRow"
      @close="dialog.edit = false"
      @resolve="onResolveRow"
    />
  </div>
</template>

<script>
import tableMixin from '@/mixin/table'
import EditErrorDialog from '../../components/EditErrorDialog.vue'
import { FindImportContentError, ConfirmImportContentError, FindImport } from '@/api/import'
import { mapGetters } from 'vuex'
import { map, find, chunk, omit } from 'lodash'

export default {
  name: 'ImportErrorTab',
  components: { EditErrorDialog },
  mixins: [tableMixin],
  props: {
    importError: {
      type: Object,
      default: () => ({}),
    },
    schema: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    loading: false,
    skipAll: false,
    tableData: [],
    // compactRawData: [],
    resolved: [],
    skiped: [],
  }),
  computed: {
    ...mapGetters(['shop']),
    taskId () {
      return this.$route.params.taskId
    },
    sortSchema () {
      const list = []
      for (const key in this.schema) {
        list.push({
          key,
          ...this.schema[key],
        })
      }
      list.sort((a, b) => a.order - b.order)
      return list
    },
    disabledConfirm () {
      if (this.totalResolve === this.importError.rowContentErrorCount) return false
      if (this.skipAll) return false
      return true
    },
    totalResolve () {
      return this.skiped.length + this.resolved.length
    },
    compactResolvedData () {
      if (this.skipAll) {
        return {
          allProcessingType: 'AllDelete',
          processings: [],
        }
      }

      const processings = []

      if (this.skiped.length) {
        this.skiped.forEach(i => {
          processings.push({
            rowId: i.rowId,
            type: 'Delete',
            updateData: {},
          })
        })
      }

      if (this.resolved.length) {
        this.resolved.forEach(row => {
          processings.push({
            rowId: row.rowId,
            type: 'Update',
            updateData: omit(row, ['rowId', 'errors']),
          })
        })
      }

      return {
        allProcessingType: null,
        processings,
      }
    },
    compactRawData () {
      const rows = map(this.importError.rowContentErrors, i => ({
        ...i.rawData,
        rowId: i.rowId,
        errors: i.schemaErrors,
      }))
      return chunk(rows, this.tableOptions.pageLimit)
    },
  },
  mounted () {
    // this.tableOptions.dataCount = this.compactRawData.length
    this.tableOptions.dataCount = this.importError.rowContentErrorCount
  },
  methods: {
    async changePage () {},
    async findImportContentError () {
      const [res, err] = await FindImportContentError({
        shopId: this.shop,
        id: this.taskId,
      })
      if (this.showErrorMsg(err)) return
      console.log(res)
    },
    async confirmImportContentError () {
      const [res, err] = await ConfirmImportContentError({
        shopId: this.shop,
        id: this.taskId,
        ...this.compactResolvedData,
      })
      if (this.showErrorMsg(err)) return null
      return res
    },
    showErrorInfo (errors) {
      const names = map(errors, i => {
        const field = find(this.sortSchema, { key: i.field })
        return field.text
      })
      return names.join('、')
    },
    onRowEdit (row) {
      this.selectRow = row
      this.dialog.edit = true
    },
    onRowSkip (row) {
      this.skiped.push(row)
    },
    isDisabled (row) {
      if (this.skipAll) return true
      const isResolved = find(this.resolved, { rowId: row.rowId })
      const isSkiped = find(this.skiped, { rowId: row.rowId })
      if (isResolved || isSkiped) return true
      return false
    },
    // 檢查task處理狀態
    async checkTaskStatus () {
      const [taskDetail, findErr] = await FindImport({
        shopId: this.shop,
        id: this.taskId,
      })
      if (this.showErrorMsg(findErr)) return

      const status = taskDetail.state

      if (status !== 'CHECKED' && status !== 'REVIEW') {
        setTimeout(async () => {
          await this.checkTaskStatus()
        }, 2000)
      } else {
        this.$emit('done')
      }
    },
    async onConfirm () {
      await this.confirmImportContentError()
      await this.checkTaskStatus()
    },
    onResolveRow (data) {
      console.log(data)
      this.resolved.push(data)
    },
  },
}
</script>

<style lang="postcss" scoped>
::v-deep .el-checkbox__inner {
  @apply w-[16px] h-[16px] border-[2px] border-info rounded-full bg-transparent;
}
::v-deep .el-checkbox__inner::after {
  @apply hidden;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner{
  @apply bg-transparent;
}
::v-deep .el-checkbox__input.is-checked::after {
  @apply absolute top-0 left-0 bottom-0 right-0 bg-primary-100;
  @apply w-[10px] h-[10px] m-auto rounded-full;
  content: "";
}
</style>
